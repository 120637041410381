import { Suspense, lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// ? Layouts
import AuthLayout from 'layout/AuthLayout';
import SetupProjectLayout from 'layout/SetupProjectLayout';
import MainLayout from 'layout/MainLayout';
import LazyLoadingIndicator from 'components/shared/LazyLoadingIndicator';
import InitialProjectGuard from 'components/shared/InitialProjectGuard';

// ? Pages
const Main = lazy(() => import('pages/Main'));
const LoginPage = lazy(() => import('pages/Login'));
const Historical = lazy(() => import('pages/Historical'));
const AboutUs = lazy(() => import('pages/AboutUs'));
const CreateViewPage = lazy(() => import('pages/CreateView'));
const Settings = lazy(() => import('views/settings/Settings'));
const CreateProject = lazy(() => import('views/createProject/CreateProject'));
const SetupProject = lazy(() => import('pages/SetupProject'));
const Logs = lazy(() => import('pages/Logs'));
const Export = lazy(() => import('pages/Export'));
const NotFound404 = lazy(() => import('pages/NotFound404'));
const DrillerMenuPage = lazy(() => import('pages/DrillerMenu'));
const DrillerMenuV1 = lazy(() => import('pages/DrillerMenuV1'));
const ReportPage = lazy(() => import('pages/ReportPage'));
const TankMenu = lazy(() => import('pages/TankMenu'));

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <InitialProjectGuard>
          <MainLayout />
        </InitialProjectGuard>
      ),
      children: [
        {
          path: 'home',
          element: <Main />,
        },
        {
          path: 'historical',
          element: <Historical />,
        },
        {
          path: 'create-view',
          element: <CreateViewPage />,
        },
        // { path: 'notifications', element: <Notifications /> },
        {
          path: 'settings',
          element: <Settings />,
        },
        {
          path: 'create-project',
          element: <CreateProject />,
        },
        {
          path: 'export',
          element: <Export />,
        },
        {
          path: '/about',
          element: <AboutUs />,
        },
        {
          path: '/logs',
          element: <Logs />,
        },
        // { path: '/pipe-settings', element: <PipeSettings /> },
        // { path: '/string-tools', element: <DrillingAndCasingTools /> },
        {
          path: '/driller-menu',
          element: <DrillerMenuV1 />,
        },
        {
          path: '/monitoring',
          element: <DrillerMenuPage />,
        },
        {
          path: '/tank-menu-v1',
          element: <TankMenu />,
        },
        // { path: '/report-page', element: <ReportPage /> },
      ],
    },
    {
      path: '/',
      element: <AuthLayout />,
      children: [{ path: 'login', element: <LoginPage /> }],
    },
    {
      path: '/',
      children: [
        {
          path: '/report-page',
          element: (
            <Suspense fallback={<LazyLoadingIndicator />}>
              <InitialProjectGuard>
                <ReportPage />
              </InitialProjectGuard>
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'setup',
      element: <SetupProjectLayout />,

      children: [
        { element: <SetupProject />, index: true },
        { path: 'create-project', element: <CreateProject /> },
      ],
    },
    { path: '*', element: <NotFound404 to="/404" replace /> },
  ]);
}
