import React from 'react';

const Icon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 7H19" stroke={color || '#1C1C1C'} strokeLinecap="round" />
    <path d="M5 12H19" stroke={color || '#1C1C1C'} strokeLinecap="round" />
    <path d="M5 17H19" stroke={color || '#1C1C1C'} strokeLinecap="round" />
  </svg>
);

export default Icon;
