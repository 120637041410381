import React from 'react';

const UnitConversionIcon = ({ size, color }) => (
  <svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 2L7.64645 1.64645L8 1.29289L8.35355 1.64645L8 2ZM8.5 21C8.5 21.2761 8.27614 21.5 8 21.5C7.72386 21.5 7.5 21.2761 7.5 21L8.5 21ZM3.64645 5.64645L7.64645 1.64645L8.35355 2.35355L4.35355 6.35355L3.64645 5.64645ZM8.35355 1.64645L12.3536 5.64645L11.6464 6.35355L7.64645 2.35355L8.35355 1.64645ZM8.5 2L8.5 21L7.5 21L7.5 2L8.5 2Z"
      fill={color || '#1C1C1C'}
    />
    <path
      d="M16 22L15.6464 22.3536L16 22.7071L16.3536 22.3536L16 22ZM16.5 2C16.5 1.72386 16.2761 1.5 16 1.5C15.7239 1.5 15.5 1.72386 15.5 2L16.5 2ZM11.6464 18.3536L15.6464 22.3536L16.3536 21.6464L12.3536 17.6464L11.6464 18.3536ZM16.3536 22.3536L20.3536 18.3536L19.6464 17.6464L15.6464 21.6464L16.3536 22.3536ZM16.5 22L16.5 2L15.5 2L15.5 22L16.5 22Z"
      fill={color || '#1C1C1C'}
    />
  </svg>
);

export default UnitConversionIcon;
