import React from 'react';

const AccessLevelIcon = ({ size, color }) => (
  <svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.5" cy="8" r="3.5" stroke={color || '#1C1C1C'} strokeLinecap="round" />
    <path
      d="M2.34913 16.9479C2.98883 14.6034 5.41473 13.5 7.845 13.5H11.155C13.5853 13.5 16.0112 14.6034 16.6509 16.9479C16.782 17.4287 16.8868 17.9489 16.9462 18.5015C17.0052 19.0507 16.5523 19.5 16 19.5H3C2.44772 19.5 1.99482 19.0507 2.05382 18.5015C2.1132 17.9489 2.21796 17.4287 2.34913 16.9479Z"
      stroke={color || '#1C1C1C'}
      strokeLinecap="round"
    />
    <ellipse
      cx="2.66651"
      cy="2.66667"
      rx="2.66651"
      ry="2.66667"
      transform="matrix(0.707107 0.707107 0.707107 -0.707107 14.8853 11.7715)"
      stroke={color || '#1C1C1C'}
    />
    <path
      d="M18.6383 14.5815L18.7017 17.9447M18.7289 19.3862L18.7017 17.9447M18.7017 17.9447L21.1041 17.9901"
      stroke={color || '#1C1C1C'}
      strokeLinecap="round"
    />
  </svg>
);

export default AccessLevelIcon;
