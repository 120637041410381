import { useEffect, useState } from 'react';
import './App.css';
import Routes from 'routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from 'components/shared/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { getSetting, getSettingLogo } from 'store/actions/settings';
import { ConfigProvider, theme } from 'antd';
import useDarkMode from 'hooks/useDarkMode';
import { darkToken } from 'theme/darkToken';
import { lighToken } from 'theme/lightToken';
import { antdComponentsTheme } from 'theme/antdComponentsTheme';
import AppErrorUi from 'components/shared/AppErrorUi';
import ServerDownUi from 'components/shared/ServerDownUi';

const { defaultAlgorithm, darkAlgorithm } = theme;

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isDarkMode, toggleDarkMode] = useDarkMode();
  const { user } = useSelector((state) => state.general);
  const { projects } = useSelector((state) => state.general);
  const { serverConnectionHasError, serverIsDown } = useSelector((state) => state.settings);
  const path = window.location.pathname;
  const authPath = !user && !path.includes('/login');

  useEffect(() => {
    dispatch(getSetting());
    dispatch(getSettingLogo());
  }, []);

  useEffect(() => {
    if (serverConnectionHasError || serverIsDown) {
      setLoading(false);
    }
  }, [serverConnectionHasError, serverIsDown]);

  useEffect(() => {
    if (!user?.username && path.includes('/login')) {
      setLoading(false);
    }
    if (user?.username && !path.includes('/login')) {
      setLoading(false);
    }
  }, [dispatch, path, user?.username]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (path === '/' && user?.username) {
      navigate('/home');
    }
  }, [path, location.pathname, authPath, user?.username, projects.length, navigate]);

  // Handle dark mode in report page
  useEffect(() => {
    if (path === '/report-page' && isDarkMode) {
      toggleDarkMode();
      localStorage.setItem('prevDarkMode', isDarkMode);
    }
  }, [isDarkMode, path]);

  if (serverIsDown && !loading) {
    return <ServerDownUi />;
  }
  if (serverConnectionHasError && !loading) {
    return <AppErrorUi />;
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        token: isDarkMode ? darkToken : lighToken,
        components: antdComponentsTheme,
      }}
    >
      {loading && (
        <div className="bg-gray-100 select-none absolute" style={{ height: '100vh', width: '100vw', zIndex: 99999 }}>
          <Loading />
        </div>
      )}
      <Routes />
      <ToastContainer
        stacked
        limit={4}
        style={{ zIndex: 99999999 }}
        position="top-right"
        toastStyle={{
          border: '0.5px solid #fff',
        }}
        autoClose={5000}
        hideProgressBar
        // newestOnTop
        closeOnClick
        // rtl={false}
        // pauseOnFocusLoss={false}
        draggable
        // pauseOnHover
        theme={isDarkMode ? 'dark' : 'light'}
      />
    </ConfigProvider>
  );
}

export default App;
