'use-strict';

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import {
  AnimatedAlarm,
  BackSign,
  CarbonReport,
  Export,
  Health,
  Log,
  Menu,
  MenuSecond,
  Monitoring,
  PrimeFileExport,
  SettingLight,
  Settings,
} from 'components/icons';
import { io } from 'socket.io-client';
import SelectOption from 'components/shared/SelectOption';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import {
  getSetting,
  getView,
  getViews,
  getDrillerView,
  setLoading,
  setViewId,
  updateSettings,
  getSettingLogo,
  getMudPumps,
  getSelectedDrillerView,
} from 'store/actions/settings';
import { httpService } from 'api/api';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import UserPopover from 'layout/components/UserPopover';

import { currentUser, loadingLayer } from 'store/actions/general';
import { Button, Dropdown, Modal, Select, Tooltip } from 'antd';
import {
  chartDelayUpdate,
  getForceRop,
  getFrequentLiveData,
  getLiveData,
  getLiveDataAlert,
  getTallyMode,
  setStatusLiveApi,
  updateAlertMessage,
  updateWellProfileTime,
} from 'store/actions/liveData';
import { HistoryOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import useDarkMode from 'hooks/useDarkMode';
import { callAutoReaload, sendAlertMudSampling } from 'store/actions/audibleAlarm';
import ApiToastHandle from 'containers/ApiToastHandle';
import SettingPopover from 'layout/components/SettingPopover';
import { listOfTimeRes } from 'utils/statics';
import getLogo from 'utils/getLogo';
import LogsSection from 'views/drillerMenuV1/containers/LogsSection';
import { getToolsInserted, getToolsInsertedWithStand } from 'store/actions/addTools';
import { getAllMemosOnCurrentSelectedTime } from 'store/actions/memos';
import { relevantPath } from 'utils/relevantPath';
import PipeController from './PipeController';
import AutoSetWob from './AutoSetWob';

const { Option } = Select;

const socket = io(process.env.REACT_APP_API_SOCKET);

const NavigationTop = ({ isExporting, shouldAlert }) => {
  const [isDarkMode] = useDarkMode();
  const [searchParams] = useSearchParams();
  const queryParamViewId = searchParams.get('id');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { liveStatus, tallyMode } = useSelector((state) => state.liveData);
  const drillingMode = useSelector((state) => state?.liveData?.data?.drillingMode);
  const [recordMessage, setRecordMessage] = useState('');
  const { chartDelayUPDATE } = useSelector((state) => state.liveData);
  const generalView = useSelector((state) => state.settings.views);
  const { drillerViews, clientLogo, selectedDrillerView } = useSelector((state) => state.settings);
  const [healthDB, setHealthDB] = useState(false);
  const [plcStatusMessage, setPlcStatusMessage] = useState('');

  const popoverRef = useRef(null);
  const [isPopcnfirmDeletOpen, setIsPopcnfirmDeletOpen] = useState(false);

  const listOfViews = window.location.pathname.includes('create-view')
    ? [...generalView, ...drillerViews]
    : !window.location.pathname.includes('driller-menu')
    ? generalView
    : drillerViews;

  const settingsData = useSelector((state) => state.settings.settings);
  const { projects, user } = useSelector((state) => state.general);
  const { autoRealod } = useSelector((state) => state.audibleAlarm);
  const findDrillerView = _.find(drillerViews, { name: user?.usersetting?.selected_view_driller });

  const project_name = Cookies.get('project_name');
  // check if is in driller menu
  const isUpdatingDrillerMenu = window.location.pathname.includes('driller-menu');

  const [currentProject, setCurrentProject] = useState({
    isEditinPath: false,
    current: {},
  });

  const [openPopover, setOpenPopover] = useState(false);
  const [openReloadModal, setOpenReloadModal] = useState(false);
  const [openMudSampleModal, setOpenMudSampleModal] = useState(false);
  // Online state
  const [settings, setSettings] = useState(settingsData);
  const [userData, setUserData] = useState(user);
  // const { healthDB } = useSocketConnection(location, dispatch, chartDelayUPDATE, setRecordMessage);
  // useWebSocketConnection(dispatch, setOpenReloadModal, setOpenMudSampleModal, user);
  const logo = getLogo(clientLogo, settingsData, isDarkMode);

  const selectedViewId =
    _.find(listOfViews, { name: isUpdatingDrillerMenu ? userData.selected_view_driller : userData.selected_view })
      ?.id ||
    _.find(listOfViews, {
      name: isUpdatingDrillerMenu ? user?.usersetting?.selected_view_driller : user?.usersetting?.selected_view,
    })?.id;

  useEffect(() => {
    dispatch(chartDelayUpdate(chartDelayUPDATE || Cookies.get('chartDelayUpdate') || 1000));
    dispatch(getSetting());
    dispatch(getSettingLogo());
    dispatch(currentUser());
    dispatch(getViews());
    dispatch(getTallyMode('tally_mode'));
    dispatch(getDrillerView());
    if (settingsData) setSettings(settingsData);
    if (userData) {
      setUserData(userData);
      dispatch(loadingLayer(false));
    }
  }, []);

  useEffect(() => {
    if (selectedDrillerView?.id || findDrillerView?.id) {
      dispatch(getSelectedDrillerView(selectedDrillerView.id || findDrillerView.id));
    }
  }, [selectedDrillerView?.id || findDrillerView?.id]);

  useEffect(() => {
    const selectedView =
      _.find(listOfViews, { name: isUpdatingDrillerMenu ? userData.selected_view_driller : userData.selected_view }) ||
      _.find(listOfViews, {
        name: isUpdatingDrillerMenu ? user?.usersetting?.selected_view_driller : user?.usersetting?.selected_view,
      });
    if (selectedView?.id && !isPopcnfirmDeletOpen && !isUpdatingDrillerMenu) {
      dispatch(getView(selectedView.id));
      dispatch(currentUser());
      Cookies.set('selectedView', selectedView.id);
    }
  }, [
    isUpdatingDrillerMenu,
    generalView,
    drillerViews,
    user?.usersetting?.selected_view,
    user?.usersetting?.selected_view_driller,
    user?.usersetting?.selected_view_driller_id,
    userData.selected_view,
    userData.selected_view_driller,
  ]);

  useEffect(() => {
    if (window.location.pathname === '/create-project' && projects) {
      const currentEditingProject = _.find(projects, { name: project_name });
      setCurrentProject({
        isEditinPath: true,
        current: currentEditingProject,
      });
    } else if (window.location.pathname !== '/create-project') {
      setCurrentProject({
        isEditinPath: false,
        current: {},
      });
    }
  }, [project_name, projects]);

  useEffect(() => {
    if (autoRealod) {
      setOpenReloadModal(false);
    }
  }, [autoRealod]);

  // useEffect(() => {
  //   let timer;

  //   if (autoRealod) {
  //     timer = setTimeout(() => {
  //       window.location.reload();
  //     }, 60000); // 60000 ms = 1 minute
  //   }

  //   return () => {
  //     if (timer) {
  //       clearTimeout(timer);
  //     }
  //   };
  // }, [autoRealod]);

  // useEffect(() => {
  //   const handleManualReload = () => {
  //     window.removeEventListener('beforeunload', handleManualReload);
  //   };

  //   window.addEventListener('beforeunload', handleManualReload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleManualReload);
  //   };
  // }, []);

  const dataRef = useRef(null);
  const lastProcessedTimeRef = useRef(0);

  // useEffect(() => {
  //   const handleRealData = (data) => {
  //     const currentTime = Date.now();
  //     const dataTime = new Date(data.datatime).getTime();

  //     // Check if the datatime is in a new second
  //     if (Math.floor(dataTime / 1000) === Math.floor(lastProcessedTimeRef.current / 1000)) {
  //       return;
  //     }

  //     lastProcessedTimeRef.current = dataTime;
  //     dataRef.current = data;

  //     if (data?.record_message) setRecordMessage(data?.record_message);

  //     if (relevantPath(location)) {
  //       if (data && data.datatime) {
  //         dispatch(getLiveData(data));
  //       }
  //     }

  //     if (relevantPath(location) && data.record_off !== 'True') {
  //       dispatch(getLiveDataAlert(data));
  //     }

  //     if (data?.error) {
  //       setRecordMessage('');
  //     }
  //   };

  //   socket.on('connect', () => {
  //     console.log('connect');
  //   });

  //   socket.on('disconnect', () => {
  //     console.log('disconnect');
  //     setTimeout(() => {
  //       socket.connect();
  //     }, 3000);
  //   });

  //   socket.on('real_data', handleRealData);

  //   const intervalId = setInterval(() => {
  //     if (
  //       dataRef.current &&
  //       !location.pathname.includes('/historical') &&
  //       dataRef.current.record_off !== 'True' &&
  //       dataRef?.current?.datatime
  //     ) {
  //       dispatch(getFrequentLiveData(dataRef.current));
  //       setRecordMessage('');
  //     }
  //   }, chartDelayUPDATE);

  //   const intervalWellProfile = setInterval(() => {
  //     if (dataRef.current && location.pathname.includes('/historical') && dataRef.current.record_off !== 'True') {
  //       dispatch(updateWellProfileTime(dataRef.current));
  //       setRecordMessage('');
  //     }
  //   }, 300000);

  //   return () => {
  //     socket.off('connect');
  //     socket.off('disconnect');
  //     socket.off('real_data', handleRealData);
  //     clearInterval(intervalId);
  //     clearInterval(intervalWellProfile);
  //   };
  // }, [location.pathname, chartDelayUPDATE, dispatch]);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('connect');
    });

    socket.on('disconnect', () => {
      console.log('disconnect');
      setTimeout(() => {
        socket.connect();
      }, 3000);
    });

    socket.on('real_data', (data) => {
      dataRef.current = data;

      if (data?.record_message) setRecordMessage(data?.record_message);

      if (relevantPath(location)) {
        if (data && data.datatime) {
          dispatch(getLiveData(data));
        }
      }

      if (relevantPath(location) && data.record_off !== 'True') {
        dispatch(getLiveDataAlert(data));
      }

      if (data?.error) {
        setRecordMessage('');
      } else {
        // Start a new timeout to check for data arrival
        // healthCheckTimeoutRef.current = setTimeout(() => {
        //   // Set healthDB to false if no data within 1 second
        //   setHealthDB(false);
        //   dispatch(setStatusLiveApi(false));
        // }, 3000); // 1000 milliseconds = 1 second
      }
    });

    const intervalId = setInterval(() => {
      if (
        dataRef.current &&
        !location.pathname.includes('/historical') &&
        dataRef.current.record_off !== 'True' &&
        dataRef?.current?.datatime
      ) {
        dispatch(getFrequentLiveData(dataRef.current));

        setRecordMessage('');
      }
    }, chartDelayUPDATE);

    const intervalWellProfile = setInterval(() => {
      if (dataRef.current && location.pathname.includes('/historical') && dataRef.current.record_off !== 'True') {
        dispatch(updateWellProfileTime(dataRef.current));
        setRecordMessage('');
      }
    }, 300000);

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('real_data');
      clearInterval(intervalId);
      clearInterval(intervalWellProfile);
      // clearTimeout(healthCheckTimeoutRef.current);
    };
  }, [location.pathname, chartDelayUPDATE]);

  useEffect(() => {
    let socket;
    let reconnectTimeout;

    const checkPlcStatus = (event) => {
      switch (event) {
        // ! When data is ok and coming
        case 'MQTT_MODE_2':
          dispatch(setStatusLiveApi(true));
          break;
        // ! When data is empty but coming
        case 'MQTT_MODE_1':
          dispatch(setStatusLiveApi(true));
          setPlcStatusMessage('Empty data.');
          break;
        // ! ٌhen data is not coming, PLC has issue or off
        case 'MQTT_MODE_0':
          dispatch(setStatusLiveApi(false));
          setPlcStatusMessage('Plc / data issue.');
          break;

        default:
          break;
      }
    };

    const checkDbHealth = (event) => {
      switch (event) {
        case 'DATABASE_MODE_ON':
          setHealthDB(true);
          break;
        case 'DATABASE_MODE_OFF':
          setHealthDB(false);
          break;
        default:
          break;
      }
    };

    const handleSocketMessage = (data) => {
      const event = JSON.parse(data)?.event;
      switch (event) {
        case 'MEMO':
          dispatch(getAllMemosOnCurrentSelectedTime());
          break;
        case 'RELOAD':
          setOpenReloadModal(true);
          break;
        case 'FORCE_ROP':
          dispatch(getForceRop());
          break;
        case 'STRING':
          dispatch(getToolsInserted());
          dispatch(getToolsInsertedWithStand('store_stand=stand'));
          break;
        case 'UPDATE_PUMPS':
          dispatch(getMudPumps());
          break;

        default:
          if (event.includes('tally_mode')) {
            dispatch(getTallyMode('tally_mode'));
          }
          if (event.includes('ALARM_DEPTH') && user?.role?.includes('mud')) {
            dispatch(sendAlertMudSampling(true));
            setOpenMudSampleModal(true);
          }
          if (event.includes('UPDATE_ALERT')) {
            dispatch(updateAlertMessage(event));
          }
          checkPlcStatus(event);
          checkDbHealth(event);
          break;
      }
    };

    const handleSocketOpen = () => {
      console.log('Connected to socket');
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }
    };

    const handleSocketClose = () => {
      console.log('disconnected from sockets');
      dispatch(setStatusLiveApi(false));
      setHealthDB(false);
      setPlcStatusMessage('');

      // Attempt to reconnect after a delay
      reconnectTimeout = setTimeout(() => {
        console.log('Attempting to reconnect...');
        connectWebSocket();
      }, 3000); // 3 seconds delay
    };

    const handleSocketError = (error) => {
      console.log('WebSocket error:', error);
      dispatch(setStatusLiveApi(false));
      setHealthDB(false);
      setPlcStatusMessage('Connection closed!');
    };

    const connectWebSocket = () => {
      socket = new WebSocket(process.env.REACT_APP_API_SOCKET_RELOAD);
      socket.addEventListener('open', handleSocketOpen);
      socket.addEventListener('close', handleSocketClose);
      socket.addEventListener('message', (event) => handleSocketMessage(event.data));
      socket.addEventListener('error', handleSocketError);
    };

    const disconnectWebSocket = () => {
      if (socket) {
        socket.removeEventListener('open', handleSocketOpen);
        socket.removeEventListener('close', handleSocketClose);
        socket.removeEventListener('message', (event) => handleSocketMessage(event.data));
        socket.removeEventListener('error', handleSocketError);
        socket.close();
      }
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }
    };

    connectWebSocket();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && socket?.readyState !== WebSocket.OPEN) {
        console.log('Reconnecting Socket...');
        disconnectWebSocket();
        connectWebSocket();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      disconnectWebSocket();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const [editView, setEditView] = useState({
    delete: false,
    createNew: false,
  });

  const handleChange = async (name) => {
    const selectedView = listOfViews.find((x) => x.name === name);
    const isAddingDrillerView = drillerViews.find((x) => x.name === name);

    if (selectedView) {
      dispatch(setLoading(true));
      const idView = selectedView.id;

      const viewIdKey = selectedView?.is_driller_menu ? 'selected_view_driller_id' : 'selected_view_id';
      const viewNameKey = selectedView?.is_driller_menu ? 'selected_view_driller' : 'selected_view';

      // Single operation to update settings and user data
      const updateState = (prevState) => ({
        ...prevState,
        [viewIdKey]: idView,
        [viewNameKey]: name,
      });

      setSettings(updateState);
      setUserData(updateState);
      // Dispatch with dynamic keys
      dispatch(updateSettings({ [viewIdKey]: idView, [viewNameKey]: name }));
      dispatch(setViewId(idView));

      Cookies.remove('selected_traces');

      if (selectedView?.is_driller_menu) {
        dispatch(getSelectedDrillerView(selectedView.id));
        return;
      }
      dispatch(getView(selectedView.id));
    }
  };

  const handleChangeChartRes = (_, data) => {
    dispatch(chartDelayUpdate(data.value));
    Cookies.set('chartDelayUpdate', data.value);
  };

  const handleChangeProject = (name) => {
    const project = _.find(projects, { name });

    setSettings((prevState) => ({
      ...prevState,
      selectedProject: {
        name: project.name,
        id: project.id,
      },
    }));
    dispatch(updateSettings({ selected_project: project.name, selected_project_id: project.id }));
  };

  const handleEditViews = (name, value) => {
    if (name === 'createNew' && window.location.pathname === '/driller-menu') {
      setEditView({
        delete: false,
        createNew: false,
      });
      window.location.replace('/create-view?is_driller_menu=true');
    } else if (name === 'createNew') {
      setEditView({
        delete: false,
        createNew: false,
      });
      window.location.replace('/create-view');
    } else {
      setEditView((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleRemoveView = (id, item) => {
    const defaultView = listOfViews.find((item) => item.name.toLowerCase() === 'default');

    const defaultDrillerView = listOfViews.find(
      (item) => item.name.toLowerCase() === 'default' && item.is_driller_menu === true
    );

    const isDrillerView = item.is_driller_menu;

    const response = (res) => {
      ApiToastHandle(200, 'view removed successfully', 'success');
      dispatch(currentUser());
      dispatch(getViews());
      dispatch(getDrillerView());

      if (selectedViewId === item.id && !item.is_driller_menu) {
        dispatch(updateSettings({ selected_view_id: defaultView.id, selected_view: defaultView.name }));
        handleEditViews('delete', false);
      }

      if (selectedViewId === item.id && isDrillerView) {
        dispatch(
          updateSettings({
            selected_view_driller: defaultDrillerView?.name || '',
            selected_view_driller_id: defaultDrillerView?.id || '',
          })
        );
        dispatch(getSelectedDrillerView(defaultDrillerView?.id));
        handleEditViews('delete', false);
      }

      // eslint-disable-next-line eqeqeq
      if (location.pathname.includes('create-view') && queryParamViewId == item.id) {
        window.location.reload();
      }
    };

    const error = (err) => {
      ApiErrorHandler(err);
    };

    if (isDrillerView) {
      httpService.removeDrillerView(response, error, id);
      return;
    }

    httpService.removeView(response, error, id);
  };

  const handleChangeOpenPopover = (isOpen) => {
    setOpenPopover(isOpen);
    handleEditViews('delete', false);
  };

  const handleEditViewItem = (id, is_driller_menu) => {
    const isCreateViewRoute = location.pathname.includes('create-view');

    const toEditView = _.find(listOfViews, { id, is_driller_menu });
    const isDrillerView = toEditView?.is_driller_menu;

    if (isCreateViewRoute) {
      window.location.replace(`/create-view?id=${id}&is_driller_menu=${isDrillerView}`);
    } else {
      navigate(`/create-view?id=${id}&is_driller_menu=${isDrillerView}`);
    }
  };

  const items = [
    {
      key: '1',
      icon: <SettingLight color={isDarkMode ? '#FFFFFF' : undefined} />,
      label: (
        <Link to="/settings">
          <div className="pl-1">settings</div>
        </Link>
      ),
    },
    {
      key: '5',
      icon: <HistoryOutlined className="p-0 m-0 text-xl" color={isDarkMode ? '#FFFFFF' : undefined} />,
      label: (
        <a href="/historical" className="pl-2">
          historical
        </a>
      ),
    },
    {
      key: '2',
      icon: <MenuSecond color={isDarkMode ? '#FFFFFF' : undefined} />,
      label: (
        <Link to="/driller-menu">
          <div className="pl-1">driller’s menu</div>
        </Link>
      ),
    },
    {
      key: '6 ',
      icon: <Monitoring color={isDarkMode ? '#FFFFFF' : undefined} />,
      label: (
        <Link to="/monitoring">
          <div className="pl-2">monitoring</div>
        </Link>
      ),
    },
    {
      key: '3',
      icon: <Log color={isDarkMode ? '#FFFFFF' : undefined} />,
      label: (
        <Link to="/logs">
          <div className="pl-1">Logs</div>
        </Link>
      ),
    },
    {
      key: '4',
      icon: <Export color={isDarkMode ? '#FFFFFF' : undefined} />,
      label: <span>Export</span>,
      children: [
        {
          key: '4-1',
          icon: <PrimeFileExport color={isDarkMode ? '#FFFFFF' : undefined} />,
          label: (
            <span className="pl-1">
              <Link to="/export" className="dark:hover:text-white">
                Export File
              </Link>
            </span>
          ),
        },
        {
          key: '4-2',
          icon: <CarbonReport color={isDarkMode ? '#FFFFFF' : undefined} />,
          label: (
            <span className="pl-1">
              <a
                href={selectedViewId ? `/report-page?viewId=${selectedViewId}` : '/report-page'}
                className="dark:hover:text-white"
              >
                Visual Report
              </a>
            </span>
          ),
        },
      ],
    },
  ];

  const handleCloseModal = () => {
    setOpenReloadModal(false);
    dispatch(callAutoReaload(true));
  };

  const handleReaoladeModal = () => {
    setOpenReloadModal(false);
    dispatch(callAutoReaload(false));
    Cookies.set('shouldReaload', false);
    Cookies.remove('timeLeft');
    window.location.reload();
  };

  const handleCloseModalMudSampleModal = () => {
    setOpenMudSampleModal(false);
    dispatch(sendAlertMudSampling(false));
  };

  const handleMudSampleModal = () => {
    setOpenMudSampleModal(false);
    dispatch(sendAlertMudSampling(false));
  };

  const AlarmModalFooter = () => (
    <div className="w-full flex justify-end items-center space-x-2">
      <Button onClick={handleReaoladeModal} className="bg-warning border-warning text-white">
        Reload now
      </Button>
    </div>
  );

  const SampleModalFooter = () => (
    <div className="w-full flex justify-end items-center space-x-2">
      <Button onClick={handleMudSampleModal}>ok</Button>
    </div>
  );

  // * VIEW FUNCTIONS
  const handleEditView = (name) => {
    if (openPopover && editView.delete && !editView.createNew) {
      handleEditViews(name, false);
    } else if (openPopover && !editView.delete && !editView.createNew) {
      handleEditViews(name, true);
    }
  };

  const removeView = (id, item) => {
    handleRemoveView(id, item);
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (!isPopcnfirmDeletOpen && popoverRef.current && !popoverRef.current?.contains(event.target)) {
        setOpenPopover(false);
      }
    };

    document.addEventListener('mousedown', handleDocumentClick);

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [isPopcnfirmDeletOpen]);


  const [counter, setCounter] = useState(null);

  useEffect(() => {
    // Check if there's a saved counter in cookies
    const savedCounter = Cookies.get('reload-counter');
    if (savedCounter) {
      setCounter(parseInt(savedCounter, 10));
    }

    // Set up the socket listener
    if (openReloadModal) {
      // Assume socketEvent is a boolean indicating the socket event occurrence
      setCounter(10); // Start the counter from 10
      Cookies.set('reload-counter', 10); // Save the initial counter value to cookies
    }
  }, [openReloadModal]);

  useEffect(() => {
    if (counter === null) return; // If counter is not initialized, do nothing

    if (counter === 0) {
      // If counter reaches 0, reload the page
      Cookies.remove('reload-counter'); // Remove the counter from cookies
      window.location.reload(); // Reload the page
    } else {
      // Set up a countdown
      const timer = setTimeout(() => {
        setCounter((prevCounter) => {
          const newCounter = prevCounter - 1;
          Cookies.set('reload-counter', newCounter); // Update the counter in cookies
          return newCounter;
        });
      }, 1000);

      // Clean up the timer on component unmount or counter change
      return () => clearTimeout(timer);
    }
  }, [counter]);

  return (
    <div id="appNavigation">
      <div
        className="
        z-50
        capitalize
        w-full
        flex-1
        flex
        flex-col
        space-y-0
        xl:space-y-0
        xl:flex-row
        items-center
        justify-between
        font-medium
        text-xs
        lg:text-sm
        border
        border-gray-200
        rounded-md
        px-3
        py-3
        lg:py-4
        bg-white
        dark:bg-dark
        dark:text-white
        dark:border-lightDark
      "
      >
        {/* right */}
        <div className="w-full xl:w-fit pb-2 xl:border-none xl:pb-0 z-30">
          <ul className="flex items-center lg:items-center justify-between md:justify-start gap-x-4">
            {location.pathname !== '/home' && (
              <li>
                <Button onClick={() => navigate(-1)} className="p-0 w-fit m-0 outline-none border-none  shadow-none">
                  <BackSign size={15} color={isDarkMode ? '#FFFFFF' : undefined} />
                </Button>
              </li>
            )}
            <li className="self-center">
              <Dropdown trigger="click" menu={{ items }}>
                <Button className="p-0 w-fit m-0 outline-none border-none  shadow-none">
                  <Menu color={isDarkMode ? '#FFFFFF' : undefined} />
                </Button>
              </Dropdown>
            </li>
            <li>
              <a href="/home">
                {logo ? (
                  <div className="w-fit max-w-28 min-w-12">
                    <img
                      src={`${process.env.REACT_APP_API_MEDIA}${logo}`}
                      className="h-[33px] lg:h-[43px] w-full"
                      alt="main logo saxon"
                    />
                  </div>
                ) : (
                  <span className="text-2xl font-semibold p-0 m-0 mb-2 capitalize">
                    {settings.company_name || 'saxon'}
                  </span>
                )}
              </a>
            </li>
            {!isExporting && (
              <>
                <li className="flex items-start lg:items-center text-base gap-x-2 flex-col gap-y-2 lg:space-y-0 lg:flex-row">
                  {!location.pathname.includes('/historical') ? (
                    <div className="flex items-center text-sm">
                      <div className="pr-2">project:</div>
                      {settingsData?.live_project}
                    </div>
                  ) : (
                    <div className="flex">
                      <SelectOption
                        size="middle"
                        className=" min-select"
                        disabled={currentProject?.isEditinPath}
                        value={
                          currentProject?.isEditinPath ? currentProject?.current?.name : settingsData?.selected_project
                        }
                        handleChange={handleChangeProject}
                        options={projects}
                      />
                    </div>
                  )}
                  <Tooltip className="flex items-center" title={drillingMode === '1' ? 'Drilling On' : 'Drilling OFF'}>
                    <span className={`${drillingMode === '1' ? 'text-success' : 'text-error'} hidden md:block`}>
                      {drillingMode === '1' ? '| Drilling' : '| Not drilling'}
                    </span>
                  </Tooltip>
                </li>
              </>
            )}
            {shouldAlert && (
              <div>
                <Button
                  danger
                  type="link"
                  // onClick={handleClickAlarm}
                  className="flex justify-start items-center gap-x-1 capitalize font-bold rounded-full p-0"
                >
                  <AnimatedAlarm />
                  <div>
                    <div>you have an alert</div>
                  </div>
                </Button>
              </div>
            )}
          </ul>
        </div>
        {/* center */}
        <div className="">
          {openReloadModal && (
            <div className=" capitalize font-semibold">
              The system has been updated and your browser will reload in <span className=' font-bold text-error'>{counter}</span> seconds
            </div>
          )}
        </div>
        {/* left */}
        <div className="self-end w-full lg:w-fit" style={{ zIndex: 49 }}>
          <ul className="flex justify-between xl:justify-end items-center gap-4 flex-wrap">
            <li>
              {/* Auto Reference WOB  */}
              <AutoSetWob />

              {/* ! PIPS LOGS */}
              <PipeController />
              {tallyMode !== 'auto_depth' && <LogsSection />}
            </li>
            <li className="flex flex-col space-y-1">
              {(window?.location?.pathname.includes('/home') ||
                window?.location?.pathname.includes('driller-menu')) && (
                <div className="flex flex-col items-center text-sm min-w-[100px]">
                  <span>Chart updates</span>
                  <Select
                    value={_.find(listOfTimeRes, { value: Number(chartDelayUPDATE) })?.name}
                    onChange={(_, data) => handleChangeChartRes(_, data)}
                    className="hover:shadow-none hover:border-gray-200 min-select min-w-[100px]"
                  >
                    {listOfTimeRes.map((item) => (
                      <Option key={item.id} value={item.value} label={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
            </li>
            <li className=" flex flex-col justify-between -space-y-1 text-sm">
              {recordMessage.length > 1 && (
                <div className={`text-error  text-sm font-semibold flash-animation-bang`}>{recordMessage}</div>
              )}

              <div className="flex items-center space-x-1 cursor-default">
                <span>PLC status:</span>
                <span
                  className={`${
                    liveStatus ? 'text-success' : 'text-error flash-animation-bang'
                  }  text-xs font-semibold ml-2`}
                >
                  {liveStatus ? 'Online' : 'Offline'}
                </span>
              </div>

              {plcStatusMessage && (
                <span className={`text-[11px] ${liveStatus ? 'text-warning' : 'text-error'}`}>{plcStatusMessage}</span>
              )}

              <div className="flex items-center space-x-1">
                <span>DB Health:</span>
                <span className={`${!healthDB && 'flash-animation-bang'}`}>
                  <Health color={`${healthDB ? '#6ABE39' : '#E84749'}`} />
                </span>
              </div>
            </li>
            <li className="flex items-center space-x-4">
              <div className="flex items-center space-x-2 relative ">
                <button
                  className="flex items-center space-x-1 text-sm"
                  type="button"
                  onClick={() => handleChangeOpenPopover(!openPopover)}
                >
                  <Settings color={isDarkMode ? '#fff' : undefined} />
                  <span>Setup views</span>
                </button>
                {openPopover && (
                  <SettingPopover
                    onSelectView={handleChange}
                    popoverRef={popoverRef}
                    listOfViews={listOfViews}
                    selectedViewId={selectedViewId}
                    settings={settings}
                    editView={editView}
                    isDarkMode={isDarkMode}
                    handleEditViewItem={handleEditViewItem}
                    setIsPopcnfirmDeletOpen={setIsPopcnfirmDeletOpen}
                    removeView={removeView}
                    handleEditView={handleEditView}
                    role={user.role}
                  />
                )}
              </div>
              <div>
                <UserPopover isDarkMode={isDarkMode} />
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/* <Modal
        footer={<AlarmModalFooter />}
        title="System message"
        open={openReloadModal}
        onOk={handleReaoladeModal}
        // onCancel={handleCloseModal}
      >
        <p>The system has been updated and your browser will reload in 60 seconds to get the update</p>
      </Modal> */}
      <Modal
        footer={<SampleModalFooter />}
        title="Depth Sampling Alarm"
        open={openMudSampleModal}
        onOk={handleMudSampleModal}
        onCancel={handleCloseModalMudSampleModal}
      >
        <p>Depth has changed 5 meter, please take a sample</p>
      </Modal>
    </div>
  );
};

export default NavigationTop;
