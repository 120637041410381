const ServerDownUi = () => (
  <div className="h-screen w-screen flex flex-col justify-center items-center gap-3">
    <img src="/shared/noServerResponse.jpg" alt="" />
    <h1 className="text-7xl font-bold text-center">500</h1>
    <h1 className="text-2xl font-bold text-center">Something went wrong with the server!</h1>
    <h2 className=" text-center">We are working on it and will come back to you soon.</h2>
  </div>
);

export default ServerDownUi;
